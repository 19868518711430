import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Stockist from 'components/Stockist'

import s from './styles.module.css'

const LocatorOverlay = ({ locatorPage, className, close, ...props }) => {
  return (
    <div
      className={classNames(s.container, className, {
        [s.containerLocatorPage]: locatorPage,
      })}
    >
      <Stockist locatorPage={locatorPage} {...props} />
      <button type="button" className={s.closeBtn} onClick={close}>
        Back
        <svg
          className={s.backIcon}
          width="14"
          height="21"
          viewBox="0 0 14 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5537 2L3.55371 10.5L12.5537 19.5"
            stroke="black"
            strokeWidth="4"
          />
        </svg>
        <svg
          className={s.closeIcon}
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.5 15.726c0 8.387-7.14 15.226-16 15.226s-16-6.84-16-15.226C.5 7.34 7.64.5 16.5.5s16 6.84 16 15.226z"
            stroke="#000"
          />
          <path
            d="M9.706 9.678l13.588 12.95m0-12.95L9.706 22.628"
            stroke="#000"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </div>
  )
}

LocatorOverlay.propTypes = {
  locatorPage: PropTypes.bool,
  className: PropTypes.string,
  close: PropTypes.func,
}
LocatorOverlay.defaultProps = {
  locatorPage: true,
  className: '',
  close: () => {},
}

export default LocatorOverlay
