import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import mapboxgl from 'mapbox-gl'
import classNames from 'classnames'
// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'
import distance from '@turf/distance'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

// import { STOCKIST_LOCATOR_DISTANCE } from 'constants'

import useClearTimeout from 'hooks/useClearTimeout'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Loading from 'components/Loading'
import LocatorOverlay from 'components/LocatorOverlay'
import LocatorList from 'components/LocatorList'

import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import s from './locator.module.css'

const Stockists = ({ location, data }) => {
  const mapRef = useRef(null)
  const controlsRef = useRef(null)
  const wrapperRef = useRef(null)
  const mapboxRef = useRef(null)
  const geocoderRef = useRef(null)
  const [geolocationActive, setGeolocationActive] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [closestStores, setClosestStores] = useState(null)
  const [stores, setStores] = useState(null)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)

  const { allHubspotCompanies } = data
  const companies = allHubspotCompanies.nodes
    .map((n) => ({
      id: n.id,
      ...n.properties,
    }))
    .filter((c) => c.stockist_visibility === 'true')

  const onlineCompanies = [
    ...companies.filter(
      (c) => c.preferred_partner === 'Online Preferred Partner'
    ),
  ]

  const initialState = {
    bounds: [
      [-10.455287, 58.694474],
      [2.398392, 50.182586],
    ],
    zoom: 4,
  }
  mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_KEY

  useClearTimeout(timeoutId)

  useEffect(() => {
    const buildStoresData = async () => {
      const storesOriginal = {
        type: 'FeatureCollection',
        features: companies
          .filter(
            (c) =>
              c.longitude_latitude &&
              c.longitude_latitude.split(', ').length === 2
          )
          .map((company) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: company.longitude_latitude
                .split(', ')
                .map((l) => parseFloat(l))
                .reverse(),
            },
            properties: {
              id: company.id,
              distance: null,
              company,
            },
          })),
      }

      const geocodingService = mapboxGeocoding({
        accessToken: process.env.GATSBY_MAPBOX_API_KEY,
      })
      const geocodingPromises = companies
        .filter(
          (c) =>
            c.zip &&
            c.zip !== '' &&
            (!c.longitude_latitude ||
              c.longitude_latitude.split(', ').length !== 2)
        )
        .map((company) =>
          geocodingService
            .forwardGeocode({
              query: company.zip,
            })
            .send()
            .then(
              (response) => {
                if (
                  response.body.features &&
                  response.body.features.length > 0
                ) {
                  const district = response.body.features[0].context.find((c) =>
                    c.id.startsWith('district.')
                  )
                  // console.info('Geocode result', response.body.features[0])
                  storesOriginal.features.push({
                    type: 'Feature',
                    geometry: response.body.features[0].geometry,
                    properties: {
                      id: company.id,
                      distance: null,
                      visible: 'false',
                      district: district ? district.text : null,
                      company,
                    },
                  })
                }
              },
              (error) => {
                console.error('Geocode query error', company.zip, error)
              }
            )
        )
      await Promise.all(geocodingPromises).then(() => {
        setStores(storesOriginal)
      })
    }
    buildStoresData()
  }, [])

  const handleSearchInputFocus = () => {
    resetMap()
  }

  const resetMap = () => {
    if (mapboxRef.current) {
      setClosestStores(null)
      stores.features.forEach((s) => {
        s.properties.visible = 'false'
        s.properties.label = null
      })
      mapboxRef.current.getSource('stores').setData(stores)
      // mapboxRef.current.setCenter([initialState.lng, initialState.lat])
      // mapboxRef.current.setZoom(initialState.zoom)
      // geocoderRef.current._inputEl.setAttribute('readonly', false)
      geocoderRef.current._inputEl.removeEventListener(
        'focus',
        handleSearchInputFocus
      )

      window.requestAnimationFrame(() => {
        mapboxRef.current.resize()
        window.requestAnimationFrame(() =>
          mapboxRef.current.fitBounds(initialState.bounds)
        )
      })
    }
  }

  const createBoundingBox = (sortedStores, searchResult) => {
    const lats = [
      ...sortedStores.map((s) => s.geometry.coordinates[1]),
      searchResult.coordinates[1],
    ]
    const lons = [
      ...sortedStores.map((s) => s.geometry.coordinates[0]),
      searchResult.coordinates[0],
    ]
    const sortedLons = lons.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })
    const sortedLats = lats.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })
    return [
      [sortedLons[0], sortedLats[0]],
      [sortedLons[sortedLons.length - 1], sortedLats[sortedLats.length - 1]],
    ]
  }

  const handleLocationSearch = (searchResultLocation, result = null) => {
    const options = { units: 'miles' }
    stores.features.forEach((store) => {
      store.properties.distance = distance(
        searchResultLocation,
        store.geometry,
        options
      )
      store.properties.visible = 'false'
    })
    stores.features.sort((a, b) => {
      if (a.properties.distance > b.properties.distance) return 1
      if (a.properties.distance < b.properties.distance) return -1
      return 0
    })

    const newClosestStores = stores.features.filter(
      result
        ? (s) => {
            return (
              s.properties.district === result.text ||
              s.properties.company.city === result.text ||
              s.properties.company.state === result.text
            )
          }
        : (s) => s.properties.distance < 15
    )

    newClosestStores.forEach((s, i) => {
      s.properties.visible = 'true'
      s.properties.label = i + 1
      s.properties.origin = `${searchResultLocation.coordinates[1]},${searchResultLocation.coordinates[0]}`
    })

    setClosestStores(
      newClosestStores.map((s) => ({
        ...companies.find((c) => c.id === s.properties.id),
        origin: `${searchResultLocation.coordinates[1]},${searchResultLocation.coordinates[0]}`,
        distance: s.properties.distance,
      }))
    )
    mapboxRef.current.getSource('stores').setData(stores)
    mapboxRef.current.resize()

    // geocoderRef.current._inputEl.setAttribute('readonly', true)
    geocoderRef.current._inputEl.addEventListener(
      'focus',
      handleSearchInputFocus
    )

    if (newClosestStores.length === 0) return

    window.requestAnimationFrame(() => {
      const boundingBox = createBoundingBox(
        newClosestStores,
        searchResultLocation
      )
      const padding =
        (window.innerWidth - wrapperRef.current.offsetWidth) / 2 + 20

      mapboxRef.current.fitBounds(boundingBox, {
        padding:
          controlsRef.current.offsetWidth === wrapperRef.current.offsetWidth
            ? { top: 85, bottom: 20, left: 20, right: 20 }
            : {
                top: 20,
                bottom: 20,
                left: padding + controlsRef.current.offsetWidth + 20,
                right: padding,
              },
      })
    })
  }

  useEffect(() => {
    function renderMap() {
      if (mapRef.current && stores && !mapboxRef.current) {
        mapboxRef.current = new mapboxgl.Map({
          container: mapRef.current,
          style: 'mapbox://styles/patronstudio/ckek52syf00u919pbm0vkmo6j',
          bounds: initialState.bounds,
          zoom: initialState.zoom,
        })

        mapboxRef.current.on('load', () => {
          mapboxRef.current.addSource('stores', {
            type: 'geojson',
            data: stores,
          })
          mapboxRef.current.addLayer({
            id: 'locations',
            type: 'symbol',
            source: 'stores',
            layout: {
              'icon-image': 'marker-try-3',
              'icon-size': 1.7,
              'icon-ignore-placement': true,
              'text-size': 12,
              'text-field': ['get', 'label'],
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-width': 0.5,
              'text-halo-color': '#ffffff',
            },
            filter: ['==', 'visible', 'true'],
          })
          mapboxRef.current.on('click', (e) => {
            const features = mapboxRef.current.queryRenderedFeatures(e.point, {
              layers: ['locations'],
            })

            if (features.length) {
              const clickedPoint = features[0]
              setSelectedCompany({
                ...companies.find((c) => c.id === clickedPoint.properties.id),
                origin: clickedPoint.properties.origin,
                distance: clickedPoint.properties.distance,
              })
            }
          })

          if (controlsRef.current && window.MapboxGeocoder) {
            geocoderRef.current = new window.MapboxGeocoder({
              accessToken: mapboxgl.accessToken,
              placeholder: 'Postcode / Location / Address',
              countries: 'GB,JE,GG,IE,IM',
            })
            // geocoderRef.current.addTo(controlsRef.current)
            geocoderRef.current.addTo('#controls')
            geocoderRef.current.on('result', ({ result }) => {
              geocoderRef.current._inputEl.blur()
              console.log('result', result)
              const nonSpecificResult =
                result.place_type.length &&
                ['district', 'region', 'country'].includes(result.place_type[0])
              console.log('nonSpecificResult', nonSpecificResult)
              handleLocationSearch(
                result.geometry,
                nonSpecificResult ? result : null
              )
            })
            geocoderRef.current.on('clear', resetMap)
          }

          setMapLoaded(true)
        })
      }
    }

    if (location.state?.modal) {
      const timeoutId = setTimeout(() => {
        renderMap()
      }, 500)
      setTimeoutId(timeoutId)
    } else {
      renderMap()
    }

    // return () => {
    //
    //   geocoder.off('result', (result) => {
    //
    //   })
    // }
  }, [stores])

  // Used if we want to pass a postcode from another page
  // useEffect(() => {
  //   if (
  //     geocoderRef.current &&
  //     location.state.stockistPostcode &&
  //     window.MapboxGeocoder
  //   ) {
  //     geocoderRef.current.query(location.state.stockistPostcode)
  //   }
  // }, [mapLoaded])

  const geolocateUser = () => {
    navigator.geolocation.getCurrentPosition((pos) => {
      if (pos.coords)
        handleLocationSearch({
          type: 'Point',
          coordinates: [pos.coords.longitude, pos.coords.latitude],
        })
      setGeolocationActive(true)
    })
  }

  const isGeolocationAvailable =
    typeof navigator !== 'undefined' &&
    navigator.geolocation &&
    !geolocationActive &&
    mapLoaded &&
    !closestStores

  const renderContent = (hasModal) => {
    return (
      <>
        <Helmet>
          <link
            href="https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css"
            rel="stylesheet"
            async
          />
          <script
            src="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.min.js"
            async
          />
        </Helmet>
        <SEO title="Find your nearest stockist | Store Locator | Chalk UK" />
        <div
          ref={mapRef}
          className={classNames(s.map, {
            [s.mapLoaded]: mapLoaded,
            [s.mapWithList]: closestStores,
            [s.mapModal]: hasModal,
          })}
          data-cy={hasModal && 'product-locator-overlay'}
        />
        <div
          ref={wrapperRef}
          className={classNames(s.wrapper, {
            [s.wrapperWithList]: closestStores,
          })}
        >
          {!mapLoaded && <Loading className={s.loading} />}
          <div
            ref={controlsRef}
            id="controls"
            className={classNames(s.controls, {
              [s.controlsBg]: selectedCompany || closestStores,
              [s.controlsClosestStores]: !geolocationActive && closestStores,
            })}
          >
            {!mapLoaded && (
              <>
                <div className={s.controlPlaceholder} />
                <div className={s.locatorPlaceholder} />
              </>
            )}
            {!geolocationActive && closestStores && (
              <button
                type="button"
                className={s.backBtn}
                onClick={() => {
                  if (geocoderRef.current) geocoderRef.current.clear()
                  else resetMap()
                  setGeolocationActive(false)
                }}
              >
                <svg
                  width="14"
                  height="21"
                  viewBox="0 0 14 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5547 2L3.55469 10.5L12.5547 19.5"
                    stroke="black"
                    strokeWidth="4"
                  />
                </svg>
              </button>
            )}
            {geolocationActive && (
              <button
                type="button"
                onClick={() => {
                  resetMap()
                  setGeolocationActive(false)
                }}
                className={s.geolocationActive}
              >
                <svg
                  className={s.backChevron}
                  width="14"
                  height="21"
                  viewBox="0 0 14 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5547 2L3.55469 10.5L12.5547 19.5"
                    stroke="black"
                    strokeWidth="4"
                  />
                </svg>
                Current location
              </button>
            )}
            {isGeolocationAvailable && (
              <button
                type="button"
                onClick={() => {
                  geolocateUser()
                }}
                className={s.geolocateButton}
                data-cy="use-location-btn"
              >
                Use my current location?
                <svg
                  width="13"
                  height="19"
                  viewBox="0 0 13 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5 1.583c2.597 0 4.875 2.073 4.875 4.435 0 2.449-2.026 5.646-4.875 10.023-2.85-4.377-4.875-7.574-4.875-10.023 0-2.362 2.278-4.435 4.875-4.435zM6.5 0C3.09 0 0 2.694 0 6.018S2.819 13.31 6.5 19C10.181 13.31 13 9.342 13 6.018S9.912 0 6.5 0zm0 8.708c-1.346 0-2.438-1.063-2.438-2.375 0-1.311 1.092-2.375 2.438-2.375s2.438 1.064 2.438 2.375c0 1.312-1.092 2.375-2.438 2.375z"
                    fill="#000"
                  />
                </svg>
              </button>
            )}
          </div>
          {selectedCompany && (
            <LocatorOverlay
              locatorPage={!hasModal}
              close={() => setSelectedCompany(null)}
              {...selectedCompany}
            />
          )}
          {closestStores && (
            <LocatorList
              locatorPage={!hasModal}
              view={(company) => setSelectedCompany(company)}
              onlineCompanies={onlineCompanies}
            >
              {closestStores}
            </LocatorList>
          )}
        </div>
      </>
    )
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) =>
        modal ? (
          <>
            <Helmet>
              <html data-modal />
            </Helmet>
            <Link to={closeTo} className={s.closeModalBtn} />
            {renderContent(true)}
          </>
        ) : (
          <Layout>
            <Helmet>
              <html data-theme-color="ebb" />
            </Helmet>
            <div className={s.containerLocatorPage}>{renderContent()}</div>
          </Layout>
        )
      }
    </ModalRoutingContext.Consumer>
  )
}

Stockists.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default Stockists

export const pageQuery = graphql`
  query allHubspotCompanies {
    allHubspotCompanies {
      nodes {
        id
        properties {
          stockist_visibility
          preferred_partner
          name
          email
          image_url
          longitude_latitude
          address
          address2
          city
          state
          zip
          about_us
          services
          opening_times
          collections_stocked
          phone
          domain
          instagram
        }
      }
    }
  }
`
