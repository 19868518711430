import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// import { STOCKIST_LOCATOR_DISTANCE } from 'constants'

import s from './styles.module.css'

const LocatorList = ({
  locatorPage,
  className,
  children,
  view,
  // onlineCompanies,
}) => {
  if (children === null) return null

  const renderItem = (company, index = null) => {
    return (
      <li key={company.id} className={s.item}>
        <button type="button" onClick={() => view(company)} className={s.btn} />
        <span className={s.title}>{company.name.split(' | ')[0]}</span>
        {company.distance && (
          <p className={s.distance}>
            {company.distance.toFixed(company.distance < 1 ? 2 : 1)} Miles
          </p>
        )}
        <span className={s.view}>View details</span>
        {index !== null && <span className={s.index}>{index + 1}</span>}
      </li>
    )
  }

  return (
    <ul
      className={classNames(s.container, className, {
        [s.containerLocatorPage]: locatorPage,
      })}
    >
      {children.length > 0 ? (
        children.map(renderItem)
      ) : (
        <li className={s.noItems}>
          {/* No stores could be found within {STOCKIST_LOCATOR_DISTANCE} miles */}
          No stores could be found within 15 miles
        </li>
      )}
      {/* <li className={s.titleItem}>Online Store Partners</li>
      {onlineCompanies.map((c) => renderItem(c))} */}
    </ul>
  )
}

LocatorList.propTypes = {
  locatorPage: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.array,
  view: PropTypes.func,
  onlineCompanies: PropTypes.array,
}
LocatorList.defaultProps = {
  locatorPage: true,
  className: '',
  children: [],
  view: () => {},
  onlineCompanies: [],
}

export default LocatorList
